/* istanbul ignore file */
//Libs:
import Bowser from 'bowser';
import JsLogger from 'js-logger';
//Events:
import * as ErrorEvents from './events/error';
import {
    PlayerEventType
} from './events/eventTypes';
import {
    PictureInPictureState,
    PictureInPictureStateUpdateEvent
} from './events/pip';
import { PlayerEvent } from './events/playerEvent';
import {
    PlayerState,
    PlayerStateUpdateEvent
} from './events/state';
import {
    PlayerAudioTrack,
    PlayerAudioTracksUpdateEvent,
    PlayerTextTrack,
    PlayerTextTracksUpdateEvent,
    PlayerTrack
} from './events/tracks';
import {
    PlayerAdBreaksUpdateEvent,
    PlayerBandwidthUpdateEvent,
    PlayerDurationUpdateEvent,
    PlayerManifestTypeUpdateEvent,
    PlayerPositionUpdateEvent,
    PlayerRecoveringEvent,
    PlayerScteSegmentsUpdateEvent,
    PlayerSeekUpdateEvent,
    PlayerVolumeUpdateEvent
} from './events/video';
//External Player:
import { Player as ShakaPlayer } from '@shakaPlayer';
import ShakaExternalPlayerFactory from './externalPlayer/implementations/shakaExternalPlayer';
//Logger:
import BasePlayerLogger from './logger/implementations/playerLogger';
//Player:
import Player from './player';
//Reactive Values:
import ReactiveValue from './reactiveValue/implementations/baseReactiveValue';
import SegmentsRecovery from './segmentsRecovery';
//Shared:
import {
    GetDrmCertificate,
    GetLicense,
    GetSessionManagerUrl,
    ParseOpportunity,
    ParsePlacement,
    ParseScte,
    PlayerAdBreak,
    PlayerScteSegment,
    TransformUrl
} from './shared';
// Style utils:
import {
    // isTizen,
    isWebOs
} from './consts/os';
import { applyStyles } from './utils/style';

interface FlutterWebPlayerOptions {
    readonly getDrmCertificate: GetDrmCertificate;
    readonly getLicense: GetLicense;
    readonly transformUrl: TransformUrl;
    readonly parseOpportunity: ParseOpportunity;
    readonly parsePlacement: ParsePlacement;
    readonly parseScte: ParseScte;
    readonly getSessionManagerUrl: GetSessionManagerUrl;
    readonly isAdPlayer: boolean;
    readonly targetOs: string;
}

declare const DEFAULT_LOG_LEVEL: string;
declare const VERSION: string;

class FlutterWebPlayerDefaultValues {
    static readonly initialBandwidth = 0;
    static readonly initialBufferLength = 0;
    static readonly shouldForceHttpsStreaming = true;
    static readonly subtitleBackgroundVOD = '';
    static readonly subtitleFontVOD = '';
    static readonly audioDescriptionLanguages = [];
    static readonly hardOfHearingSubtitleLanguages = [];
    static readonly isLive = false;
    static readonly isVod = false;
    static readonly diagnosticInfo = '';
}

class FlutterWebPlayer extends Player {
    constructor(opt: FlutterWebPlayerOptions) {
        const playerLogger = new BasePlayerLogger({
            window: window,
            source: JsLogger,
            loggerLevelLabel: 'playerloglevel',
            defaultLevel: DEFAULT_LOG_LEVEL,
        });

        const mainLogger = playerLogger.forName(opt.isAdPlayer ? 'Player.Ads' : 'Player.Main');

        mainLogger.info(`Player Version: ${VERSION}`);
        mainLogger.info(`Player Logger Level: ${DEFAULT_LOG_LEVEL}`);

        const videoContainer = window.document.createElement('div');
        let videoElement = null;

        const forceVideoElementCreationOnLoad = isWebOs(opt.targetOs) && opt.isAdPlayer;

        if (!forceVideoElementCreationOnLoad) {
            videoElement = window.document.createElement('video');
            videoContainer.appendChild(videoElement);
            applyStyles(videoContainer);
            applyStyles(videoElement);
        }

        const shakaPlayer = new ShakaPlayer(videoElement);

        shakaPlayer.setVideoContainer(videoContainer);

        const externalPlayer = ShakaExternalPlayerFactory.create({
            playerLogger,
            transformUrl: opt.transformUrl,
            parseOpportunity: opt.parseOpportunity,
            parsePlacement: opt.parsePlacement,
            parseScte: opt.parseScte,
            getDrmCertificate: opt.getDrmCertificate,
            getLicense: opt.getLicense,
            platform: Bowser.getParser(window.navigator.userAgent),
            customLicenseProtocol: 'dart',
            shakaPlayer,
            storage: window.localStorage,
            segmentsRecoveryService: new SegmentsRecovery(),
            isAdPlayer: opt.isAdPlayer,
            getSessionManagerUrl: opt.getSessionManagerUrl,
            videoContainer: videoContainer,
            targetOs: opt.targetOs,
        });

        const reactiveValues = {
            bandwidthValue: new ReactiveValue<number>(FlutterWebPlayerDefaultValues.initialBandwidth),
            bufferLength: new ReactiveValue<number>(FlutterWebPlayerDefaultValues.initialBufferLength),
            shouldForceHttpsStreaming: new ReactiveValue<boolean>(FlutterWebPlayerDefaultValues.shouldForceHttpsStreaming),
            subtitleFontVOD: new ReactiveValue<string>(FlutterWebPlayerDefaultValues.subtitleFontVOD),
            subtitleBackgroundVOD: new ReactiveValue<string>(FlutterWebPlayerDefaultValues.subtitleBackgroundVOD),
            audioDescriptionLanguages: new ReactiveValue<string[]>(FlutterWebPlayerDefaultValues.audioDescriptionLanguages),
            hardOfHearingSubtitleLanguages: new ReactiveValue<string[]>(FlutterWebPlayerDefaultValues.hardOfHearingSubtitleLanguages),
            isLive: new ReactiveValue<boolean>(FlutterWebPlayerDefaultValues.isLive),
            isVod: new ReactiveValue<boolean>(FlutterWebPlayerDefaultValues.isVod),
            diagnosticInfo: new ReactiveValue<string>(FlutterWebPlayerDefaultValues.diagnosticInfo),
        };

        const initialPlayerInfo = {
            playerVersion: ShakaPlayer.version,
            playerName: 'Shaka',
            streamingProtocol: externalPlayer.protocol,
            securityLevel: '',
        };

        super({
            videoElement,
            videoContainer,
            externalPlayer,
            reactiveValues,
            playerLogger,
            initialPlayerInfo,
            isAdPlayer: opt.isAdPlayer,
            targetOs: opt.targetOs,
            forceVideoElementCreationOnLoad,
        });

        if (opt.isAdPlayer) {
            window.__flutter_web_ad_player = this;
        } else {
            window.__flutter_web_player = this;
        }

        window.__flutter_web_player_emulate_browser_is_not_supported = false;
    }
}

declare global {
    interface Window {
        __corrupted__?: number;
        __chunks__?: number;
        __flutter_web_player_emulate_browser_is_not_supported: boolean;
        __flutter_web_player: FlutterWebPlayer;
        __flutter_web_ad_player: FlutterWebPlayer;
        WebPlayerNamespace: {
            //player:
            FlutterWebPlayer: { new(opt: FlutterWebPlayerOptions): FlutterWebPlayer },
            //tracks:
            PlayerTrack: { new(id: string, language: string, isActive: boolean, role: string): PlayerTrack },
            //events:
            PlayerEvent: { new(type: string): PlayerEvent },
            PlayerTextTracksUpdateEvent: { new(textTracks: PlayerTextTrack[]): PlayerTextTracksUpdateEvent },
            PlayerAudioTracksUpdateEvent: { new(audioTracks: PlayerAudioTrack[]): PlayerAudioTracksUpdateEvent },
            PlayerAdBreaksUpdateEvent: { new(adBreaks: PlayerAdBreak[]): PlayerAdBreaksUpdateEvent },
            PlayerScteSegmentsUpdateEvent: { new(scteSegments: PlayerScteSegment[]): PlayerScteSegmentsUpdateEvent },
            PlayerManifestTypeUpdateEvent: { new(manifestType: string): PlayerManifestTypeUpdateEvent },
            PlayerDurationUpdateEvent: { new(duration: number): PlayerDurationUpdateEvent },
            PlayerVolumeUpdateEvent: { new(volume: number): PlayerVolumeUpdateEvent },
            PlayerPositionUpdateEvent: { new(position: number): PlayerPositionUpdateEvent },
            PlayerBandwidthUpdateEvent: { new(bandwidth: number): PlayerBandwidthUpdateEvent },
            PlayerSeekUpdateEvent: { new(isSeeking: boolean): PlayerSeekUpdateEvent },
            PlayerRecoveringEvent: { new(isRecovering: boolean): PlayerRecoveringEvent },
            PlayerStateUpdateEvent: { new(): PlayerStateUpdateEvent },
            PictureInPictureStateUpdateEvent: { new(): PictureInPictureStateUpdateEvent },
            ErrorAnalyticsData: { new(isMuted: boolean, isPageVisible: boolean): ErrorEvents.ErrorAnalyticsData },
            PlayerErrorEvent: { new(): ErrorEvents.PlayerErrorEvent },
            EmptyManifestUrlErrorEvent: { new(): ErrorEvents.EmptyManifestUrlErrorEvent },
            BrowserIsNotSupportedPlayerErrorEvent: { new(): ErrorEvents.BrowserIsNotSupportedPlayerErrorEvent },
            LoadFailedPlayerErrorEvent: { new(): ErrorEvents.LoadFailedPlayerErrorEvent },
            UnLoadFailedPlayerErrorEvent: { new(): ErrorEvents.UnLoadFailedPlayerErrorEvent },
            ExternalPlayerErrorEvent: {
                new(
                    code: number,
                    data: unknown,
                    name: string,
                    analyticsData: ErrorEvents.ErrorAnalyticsData,
                    httpCode: number | null,
                    isStreamingError: boolean
                ): ErrorEvents.ExternalPlayerErrorEvent,
            },
            //enums:
            // eslint-disable-next-line @typescript-eslint/ban-types
            PlayerEventType: {},
            // eslint-disable-next-line @typescript-eslint/ban-types
            PlayerState: {},
            // eslint-disable-next-line @typescript-eslint/ban-types
            PictureInPictureState: {},
            // eslint-disable-next-line @typescript-eslint/ban-types
            PlayerErrorCode: {},
        };
    }
    interface Event {
        detail: unknown;
    }
}

window.WebPlayerNamespace = {
    //player:
    FlutterWebPlayer,
    //tracks:
    PlayerTrack,
    //events:
    PlayerEvent,
    PlayerTextTracksUpdateEvent,
    PlayerAudioTracksUpdateEvent,
    PlayerAdBreaksUpdateEvent,
    PlayerScteSegmentsUpdateEvent,
    PlayerManifestTypeUpdateEvent,
    PlayerDurationUpdateEvent,
    PlayerVolumeUpdateEvent,
    PlayerPositionUpdateEvent,
    PlayerBandwidthUpdateEvent,
    PlayerStateUpdateEvent,
    PlayerSeekUpdateEvent,
    PlayerRecoveringEvent,
    PictureInPictureStateUpdateEvent,
    ErrorAnalyticsData: ErrorEvents.ErrorAnalyticsData,
    PlayerErrorEvent: ErrorEvents.PlayerErrorEvent,
    EmptyManifestUrlErrorEvent: ErrorEvents.EmptyManifestUrlErrorEvent,
    BrowserIsNotSupportedPlayerErrorEvent: ErrorEvents.BrowserIsNotSupportedPlayerErrorEvent,
    LoadFailedPlayerErrorEvent: ErrorEvents.LoadFailedPlayerErrorEvent,
    UnLoadFailedPlayerErrorEvent: ErrorEvents.UnLoadFailedPlayerErrorEvent,
    ExternalPlayerErrorEvent: ErrorEvents.ExternalPlayerErrorEvent,
    //enums:
    PlayerEventType,
    PlayerState,
    PictureInPictureState,
    PlayerErrorCode: ErrorEvents.PlayerErrorCode,
};
